html {
	scroll-behavior: smooth;
}

body {
	background-color: #F3F5F7 !important;
}

.Toastify__toast {
	font-family: 'LamaSans';
	font-weight: 500;
}

*::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
	background-color: #FAFAFA;
}

*::-webkit-scrollbar {
	width: 8px;
	height: 5px;
	background-color: #FAFAFA;
}

*::-webkit-scrollbar-thumb {
	border-radius: 2px;
	-webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, .3);
	background-color: #254675;
}

div::-webkit-scrollbar {
	width: 5px !important
}

div::-webkit-scrollbar-thumb {
	background-color: #777 !important
}

.Toastify__toast {
	font-family: var(--cairo-font)
}

.ql-editor {
	padding: 0px !important;
}

.MuiCheckbox-root .MuiSvgIcon-root {
	transform: scaleX(1) !important;
}

hr {
	background-color: rgb(255, 255, 255);
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}